import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { Pagination, Autoplay } from "swiper";  // <-- FIXED IMPORT
import ItodoImage from "../../../elements/itodo-img";
import { NavLink } from "react-router-dom";

export function SectionPodcastStudioInner() {
    return (
        <>
            <div className="row">
                {/* Swiper Slider for 3 Images */}
                <div className="col-lg-6 col-md-12">
                    <div className="sx-about-bx4-media">
                        <Swiper
                            modules={[Pagination, Autoplay]}
                            spaceBetween={20}
                            slidesPerView={1}
                            loop={true}
                            autoplay={{ delay: 3000 }}
                            pagination={{ clickable: true }}
                        >
                            <SwiperSlide>
                                <ItodoImage src="images/about/Podcast1.png" alt="Podcast Studio 1" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ItodoImage src="images/about/Podcast2.png" alt="Podcast Studio 2" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <ItodoImage src="images/about/Podcast3.png" alt="Podcast Studio 3" />
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>

                {/* Text Content */}
                <div className="col-lg-6 col-md-12">
                    <div className="sx-about-bx4-content">
                        <h2 className="sx-title" style={{fontSize:"3.3rem"}}>Elevate Your Podcasting Experience</h2>
                        <p>Welcome to <strong>Azown Podcast Studio</strong>, where high-quality sound meets creativity. Our professional-grade studio is designed for podcasters, content creators, and brands looking to produce outstanding audio.</p>
                        <ul className="sx-arrow-circle-checked">
                            <li>🎙️ <strong>Broadcast-Quality Microphones</strong></li>
                            <li>🎛️ <strong>Mixing & Editing Suite</strong></li>
                            <li>🔊 <strong>Fully Soundproof Recording Booth</strong></li>
                            <li>🎤 <strong>News Anchoring</strong></li>
                            <li>🎬 <strong>Video Podcast Setup Available</strong></li>
                        </ul>
                        <NavLink to="/contact-us" className="site-button sx-btn-primary icon sx-btn-lg">
                            <i className="fa fa-long-arrow-right" />
                            Book a Session
                        </NavLink>
                    </div>
                </div>
            </div>
        </>
    );
}

function SectionPodcastStudio() {
    return (
        <div className="section-full p-t110 p-b80 sx-bg-dark sx-about-bx4-outer"style={{ paddingTop: 0 }}>
            <div className="container">
                <div className="section-content">
                    <div className="sx-about-bx4-content-wrap p-b70">
                        <SectionPodcastStudioInner />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SectionPodcastStudio;
