import { publicUrlFor } from "../../../../globals/constants";
import { useState } from "react";
import ReactPlayer from "react-player/youtube"; // Import ReactPlayer

function SectionVideo1() {
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility

  // Open modal
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Close modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div
        className="section-full sx-video-1-outer bg-cover"
        style={{ backgroundImage: `url(${publicUrlFor("images/vid-1-bg.jpg")})` }}
      >
        <div className="sx-bg-overlay" />
        <div className="container">
          <div className="sx-video-section text-center">
            <h2 className="sx-large-title">A Journey Defined by Innovation</h2>
            <div className="sx-video-btn-wrap">
              <button className="play-button circle" onClick={openModal}>
                <i className="flaticon-play" />
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Video Modal */}
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button className="close-button" onClick={closeModal}>
              ×
            </button>
            <ReactPlayer
              url="https://youtu.be/QIeNiKoSGbo" // Replace with your YouTube link
              playing
              controls
            //  width="100%"
            //  height="80%"
            />
          </div>
        </div>
      )}
    </>
  );
}

export default SectionVideo1;
