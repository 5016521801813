import CountUp from "react-countup";

function SectionCounter1() {
    return (
        <>
            <div className="counter-blocks flex items-center justify-center" style={{backgroundColor:"#fd4c7d",paddingTop:"10px"}}>
                <div className="row when-item-four">
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 text-center">
                        <div className="x-count">
                            <h2 className="x-title" >
                                <span className="x-cont-before">+</span>
                                <span className="counter">
                                    <CountUp end={95} duration={5} />
                                </span>
                                {/* <span className="x-cont-after">K</span> */}
                            </h2>
                            <div className="x-count-info">Our Active Member</div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 text-center">
                        <div className="x-count">
                            <h2 className="x-title">
                                <span className="x-cont-before">+</span>
                                <span className="counter">
                                    <CountUp decimals={0} end={140} duration={5} />
                                </span>
                                {/* <span className="x-cont-after">K</span> */}
                            </h2>
                            <div className="x-count-info">Our Total Project</div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 text-center">
                        <div className="x-count">
                            <h2 className="x-title">
                                <span className="x-cont-before">+</span>
                                <span className="counter">
                                    <CountUp end={10} duration={5} />
                                </span>
                            </h2>
                            <div className="x-count-info">Our Winning Award</div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 text-center">
                        <div className="x-count">
                            <h2 className="x-title">
                                <span className="x-cont-before">+</span>
                                <span className="counter">
                                    <CountUp end={30} duration={5} />
                                </span>
                            </h2>
                            <div className="x-count-info">Our Team Member</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SectionCounter1;