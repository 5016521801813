import React, { useEffect } from 'react';
import SectionCounter1 from "../../../sections/home/index/section-counter1";
import { loadScript } from "../../../../globals/constants";
import {FaCameraRetro} from 'react-icons/fa';
import {FaSlidersH } from 'react-icons/fa';
import {FaVideo} from 'react-icons/fa';
import Podcaststudio from "../services/Podcaststudio";

function Videoproductiondetail() {
    useEffect(() => {
        loadScript("js/custom.js");
    }, []);

    return (
        <>
            {/* Service Detail SECTION START */}
            <div className="section-full p-b80 sx-ser-detail-outer sx-bg-white">
                <div className="container">
                    <div className="section-content">
                        <div className="sx-our-service-detail">
                            {/* TITLE START */}
                            <div className="section-head2 flex flex-col items-center">
                                <div className="sx-head-s-title text-center text-2xl font-bold mt-4">
                                <h2>Video Production</h2></div>
                                <div className="sx-head-l-title text-center">
                                    <h2 className="sx-title2">Creating Visual Stories That Inspire</h2>
                                    <hr style={{ border: '1px solid #ccc', margin: '20px 0' }} />
                                </div>
                            </div>
                            {/* TITLE END */}
                            <div className="sx-our-service-info m-b80">
                                <p>
                                Video is one of the most powerful mediums for storytelling and connecting with your audience. Our video production services are designed to bring your vision to life, from concept to final cut. Whether it's a promotional video, documentary video production, or social media video content, we specialize in creating engaging video content that resonates with your target audience. 
                                </p>
                                <p>
                                Our team specializes in a wide range of video production services, including scripting, filming, editing, and post-production. We collaborate closely with you to ensure each project aligns with your objectives and resonates with your target audience. Whether you're looking for promotional video production, corporate video content, or social media video creation, we provide end-to-end solutions to bring your vision to life.
                                </p>
                                <div className="row sx-about-icon-bx1-column p-t50 p-b20">
                                    <div className="col-lg-4 col-md-6 m-b30">
                                        <div className="sx-icon-bx-2">
                                            <div className="sx-media">
                                            <FaCameraRetro style={{ fontSize: "24px", color: "#333" }} />
                                            </div>
                                            <div className="sx-icon-bx-info">
                                                <span className="sx-icon-bx-title">High-Quality Filming</span>
                                                <span className="sx-icon-bx-title-info">Expert Filming Production Techniques</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 m-b30">
                                        <div className="sx-icon-bx-2">
                                            <div className="sx-media">
                                            <FaSlidersH style={{ fontSize: "24px", color: "#333" }} />
                                            </div>
                                            <div className="sx-icon-bx-info">
                                                <span className="sx-icon-bx-title">Editing & Post-Production</span>
                                                <span className="sx-icon-bx-title-info">Polished and refined results</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 m-b30">
                                        <div className="sx-icon-bx-2">
                                            <div className="sx-media">
                                            <FaVideo style={{ fontSize: "24px", color: "#333" }} />
                                            </div>
                                            <div className="sx-icon-bx-info">
                                                <span className="sx-icon-bx-title">Aerial Videography</span>
                                                <span className="sx-icon-bx-title-info">Stunning aerial perspectives</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p>
                                With an emphasis on creativity and technical precision, we deliver visually compelling videos that resonate with viewers. Our team is dedicated to producing video content that not only tells your story but also enhances your brand’s reach and impact. Whether it's for brand storytelling, promotional videos, or social media video marketing, we focus on creating engaging videos that drive results.
                                </p>
                            </div>
                            <Podcaststudio/>
                            <SectionCounter1 />

                        </div>
                    </div>
                </div>
            </div>
            {/* Service Detail SECTION END */}
        </>
    );
}

export default Videoproductiondetail;

